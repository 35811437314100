'use client';
import fusionLogo from '../../public/favicon/favicon.png';
import Img from '@/components/atom/img';

export default function Loading() {
  return (
    <div className="h-screen-safe w-screen-safe flex flex-col items-center justify-center bg-purple-brand">
      <div className="rounded-full overflow-hidden animate-pulse">
        <Img src={fusionLogo} height={80} width={80} alt="fusion" bypassValidation />
      </div>
      <p className="animate-pulse text-white">Loading...</p>
    </div>
  );
}
